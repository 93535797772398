<template>
  <div class="ele-body" v-loading="loading">
    <el-tabs v-model="activeName" >
        <el-tab-pane label="支付宝" name="first">
            <el-card shadow="never">
                <el-form ref="form" :model="payList" label-width="140px">
                    <div class="innerBox">
                        <el-form-item label="合作者身份ID：" >
                            <el-input v-model="payList.ali_pay.partner" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="支付宝账号：">
                            <el-input v-model="payList.ali_pay.seller_id" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="APPid：">
                            <el-input v-model="payList.ali_pay.app_id" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="privatekey：">
                              <el-input :rows="10" v-model="payList.ali_pay.privatekey" disabled type="textarea" clearable  class="w-600"/>
                        </el-form-item>
                        <el-form-item label="publickey：">
                             <el-input :rows="10" v-model="payList.ali_pay.publickey"  disabled type="textarea" clearable  class="w-600"/>
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>
        </el-tab-pane>
        <el-tab-pane label="微信" name="second" >
            <el-card shadow="never">
                <el-form ref="form" :model="payList" label-width="140px">
                    <div class="innerBox">
                        <el-form-item label="APPid：">
                            <el-input v-model="payList.wx_pay.appid" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="MCHID：">
                            <el-input v-model="payList.wx_pay.mchid" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="KEY：">
                            <el-input v-model="payList.wx_pay.key" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="APPSECERT：">
                            <el-input v-model="payList.wx_pay.appsecret" class="w-600" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="apiclient_cert证书：">
                              <el-input :rows="10" disabled v-model="payList.wx_pay.apiclient_cert" type="textarea" clearable  class="w-600"/>
                        </el-form-item>
                        <el-form-item label="apiclient_key证书：" >
                             <el-input :rows="10"  disabled v-model="payList.wx_pay.apiclient_key"  type="textarea" clearable  class="w-600"/>
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>
        </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SysUser",
  data() {
    return {
      activeName:'first',
      payList:{ali_pay:{},wx_pay:{}},
      loading:true
    }
  },
  created(){
    this.getAliPay()

  },

  computed: {
      ...mapGetters(["permission"]),
  },
  mounted() {},
  methods:{
    getAliPay(){
      const loading = this.$loading({lock: true});
      this.$http.get('sms/get_pay_config').then(res=>{
        loading.close()
        let data=JSON.parse(res.data)
        this.payList=data
        this.loading=false
      })
    },
  },
}
</script>

<style scoped>
    .ele-block .el-upload-dragger {
        width: 100%;
    }
    .changeStyle{
        width:150px;margin-right:30px;
    }
    .ele-body{
        padding:15px;
    }
    .el-upload-list el-upload-list--text{display:none !important}
    /deep/.el-tabs__item{
        padding:0 20px !important;
        font-size: 15px;
    }
    /deep/.el-tabs__nav-scroll{
        background: white !important;
        padding-top: 10px;
    }
    .drop-down-first{
        width:150px;
        margin-right:10px;
    }
    .drop-down-second{
       width:150px; 
    }
    .search-margin{
        margin-left: 10px;
    }
    .boxTitle{padding:8px}
    .orderImgBox{margin-left: 10px;height: 80px;}
    .orderImgBox span{
        font-size: 36px;
    }
    .user-info-tabs >>> .el-tabs__nav-wrap {
        padding-left: 20px;
    }
    .innerBox{
        margin-left: 120px;
        padding-top: 20px;
    }
    .innerBoxTo{
        width:60%;
        margin: 0 auto;
        padding-top: 20px;
    }
    .innerTitle{
        font-size: 16px;
        margin-bottom: 20px;
        margin-left: 13px;
    }

</style>

